#HomeBlurb {
    text-align: center;
    font-size: 25px;
}
.text-big {
    display: block;
    font-size: 35px;
    font-weight: bold;
    color: #00688B;
}

.big-red {
    color: #a62a2a;
    font-size: inherit;
}