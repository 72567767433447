@import url(https://fonts.googleapis.com/css?family=Oswald&display=swap);
* {
  box-sizing: border-box;
  font: bold 18px "Oswald";
}
body {
  margin: 0;
  padding: 0;
  
}

.App {
  
  width: 100%;
  max-width: 1500px;
  margin: 0 auto 20px auto;
  min-height: 600px;
  
}

.App > * {
  padding: 8px;
}
header {
  height: 50px;
  width: 100%; 
  background: linear-gradient(#87ceff, white);
}

.toplogo {
  height: 45px;
  float: left;
  clear: left;
}
nav.top-nav {
  transition: all 500ms;
  float: right;
  clear: right;
  width: 50%;
  text-align: right;
}

nav .top-nav .nav-closed {
  width: 45px;
  padding-left: 80px;
}
nav.top-nav a {
  text-decoration: none;
  color: #4682b4;
  padding: 3px 8px;

  transition: all 600ms;
}

nav.top-nav a.active {
  color: #404040;
}

nav.top-nav a:hover {
  color: #404040;
}

.hamburger {
  position: fixed;
  right: 15px;
  top: 15px;

  display: none;
  flex-direction: column;
  justify-content: space-between;
  width: 40px;
  height: 35px;
  cursor: pointer;
  z-index: 10;
  opacity: 0.6;
  transition: all 500ms;
}

.hamburger .thick-line,
.hamburger .thin-line {
  width: 40px;
  background-color: #4682b4;
  border-radius: 3px;
}
.hamburger .thick-line {
  height: 7px;
}
.hamburger .thin-line {
  height: 3px;
}

.hamburger:hover {
  opacity: 1;
}
#content {
  width: 100%;
  
  
  min-height: 600px;
  padding: 5px;
}
.content-row{
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content:space-between;
 

}

.seperator {
  height: 5px;
  width: 80%;
  background: linear-gradient(to right, white, #8A8A8A, white);
  margin: 30px auto;

}



@media screen and (max-width: 650px) {
  header {
    padding: 10px;
  }
  .hamburger {
    display: flex;
  }
  nav.top-nav {
    position: fixed;
    z-index: 9;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: #363636;
    width: 0;
    overflow: hidden;
    text-align: left;
  }

  nav.top-nav a {
    font-size: 25px;
    width: 280px;
    display: block;
    overflow: hidden;
  }

  nav.top-nav a.active {
    color: #a62a2a;
  }
  nav.top-nav a:first-child {
    margin-top: 55px;
  }

  .content-row{
    flex-direction: column; 
  }
}

@media screen and (min-width: 651px) {
  nav.top-nav {
    width: 50% !important;
  }

 
}



/* HomeDisplay */
:root{
  --first-big-text-margin: 100px;
}
#HomeDisplay {
  width: 100%;
  background: linear-gradient(to right, #4A4A4A,#212121,#4A4A4A);
  padding: 4px;
  border-radius: 4px;
}

.big-home{
  width: 40%;
  margin: 5px 0 5px 100px;
  
}
.big-text{
  display: block;
  color: #FFFFFF;
  opacity: 0.7;
  font-size: 70px;
  text-transform: uppercase;
  font-weight: bold;
}

span.big-text:nth-of-type(1){
  opacity: 0;
  margin-left: 300px;
  -webkit-animation-name: big-text-move-1;
          animation-name: big-text-move-1;
 
  -webkit-animation-fill-mode: forwards;
 
          animation-fill-mode: forwards;
  -webkit-animation-duration: 2s;
          animation-duration: 2s;
}

span.big-text:nth-of-type(2){
  opacity: 0;
  margin-left: 200px;
  -webkit-animation-name: big-text-move-2;
          animation-name: big-text-move-2;
  -webkit-animation-delay: 2s;
          animation-delay: 2s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-duration: 2s;
          animation-duration: 2s;
}

span.big-text:nth-of-type(3){
  opacity: 0;
  margin-left: 600px;
  -webkit-animation-name: big-text-move-3;
          animation-name: big-text-move-3;
  -webkit-animation-delay: 3s;
          animation-delay: 3s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-duration: 2s;
          animation-duration: 2s;
}
@-webkit-keyframes big-text-move-1 {
  from {

    opacity: 0;
    margin-left:0;
  }
  to {opacity: .3;margin-left: 300px;}
}
@keyframes big-text-move-1 {
  from {

    opacity: 0;
    margin-left:0;
  }
  to {opacity: .3;margin-left: 300px;}
}

@-webkit-keyframes big-text-move-2 {
  from {

    opacity: 0;
    margin-left:500px;
  }
  to {opacity: .3;margin-left: 200px;}
}

@keyframes big-text-move-2 {
  from {

    opacity: 0;
    margin-left:500px;
  }
  to {opacity: .3;margin-left: 200px;}
}

@-webkit-keyframes big-text-move-3 {
  from {

    opacity: 0;
    margin-left:0px;
  }
  to {opacity: .3;margin-left: 500px;}
}

@keyframes big-text-move-3 {
  from {

    opacity: 0;
    margin-left:0px;
  }
  to {opacity: .3;margin-left: 500px;}
}
@media screen and (max-width: 650px) {
 .big-home{
   width: 90%;
 }
 span.big-text{
   font-size: 25px;
   -webkit-animation: none !important;
           animation: none !important;
   margin: 0 !important;
   opacity: 1  !important;
  
 }
}

.card{
    border-radius: 6px;
    text-decoration: none;
    display: block;
    transition: all 1s;
    width: 33%;
    min-height: 300px;
    
}
.card:hover{
    opacity: 1 !important;
}


.card:nth-of-type(1){
    background: linear-gradient(#AF4035, #E3170D);
}
.card:nth-of-type(2){
    background: linear-gradient(#FCDC3B, #FFEC8B);
}
.card:nth-of-type(3){
    background: linear-gradient(#00688B, #009ACD);
}

.dev_art_title {
    color: white;
    display: block;
    padding: 5px;
    text-align: center;
    background: linear-gradient(#2E2E2E, #4D4D4D);
    border-radius: 6px 6px 0 0;
    font-size: 20px;
   
}

.dev-art-img{
    height: 200px;
    width: 100%;
    background-size: 100% !important;
    background-position: center 
}
.dev-art-descr{
    margin:6px;
    color: #424242;
    font-size: 16px;
    text-align: center;
}

@media screen and (max-width: 651px) {
    
    .card {
        width: 100%;
        margin-top: 8px;
    }
  }
#HomeBlurb {
    text-align: center;
    font-size: 25px;
}
.text-big {
    display: block;
    font-size: 35px;
    font-weight: bold;
    color: #00688B;
}

.big-red {
    color: #a62a2a;
    font-size: inherit;
}
