/* HomeDisplay */
:root{
  --first-big-text-margin: 100px;
}
#HomeDisplay {
  width: 100%;
  background: linear-gradient(to right, #4A4A4A,#212121,#4A4A4A);
  padding: 4px;
  border-radius: 4px;
}

.big-home{
  width: 40%;
  margin: 5px 0 5px 100px;
  
}
.big-text{
  display: block;
  color: #FFFFFF;
  opacity: 0.7;
  font-size: 70px;
  text-transform: uppercase;
  font-weight: bold;
}

span.big-text:nth-of-type(1){
  opacity: 0;
  margin-left: 300px;
  animation-name: big-text-move-1;
 
  animation-fill-mode: forwards;
  animation-duration: 2s;
}

span.big-text:nth-of-type(2){
  opacity: 0;
  margin-left: 200px;
  animation-name: big-text-move-2;
  animation-delay: 2s;
  animation-fill-mode: forwards;
  animation-duration: 2s;
}

span.big-text:nth-of-type(3){
  opacity: 0;
  margin-left: 600px;
  animation-name: big-text-move-3;
  animation-delay: 3s;
  animation-fill-mode: forwards;
  animation-duration: 2s;
}
@keyframes big-text-move-1 {
  from {

    opacity: 0;
    margin-left:0;
  }
  to {opacity: .3;margin-left: 300px;}
}

@keyframes big-text-move-2 {
  from {

    opacity: 0;
    margin-left:500px;
  }
  to {opacity: .3;margin-left: 200px;}
}

@keyframes big-text-move-3 {
  from {

    opacity: 0;
    margin-left:0px;
  }
  to {opacity: .3;margin-left: 500px;}
}
@media screen and (max-width: 650px) {
 .big-home{
   width: 90%;
 }
 span.big-text{
   font-size: 25px;
   animation: none !important;
   margin: 0 !important;
   opacity: 1  !important;
  
 }
}